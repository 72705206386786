<template>
  <b-container fluid v-if="client">
    <b-row>
      <b-col cols="12">
        <h2 class="text-center my-4">Uredi klijenta</h2>
      </b-col>
      <b-col md="12" class="flex-center">
        <b-form v-on:submit.prevent="updateClient" class="w-75">
          <b-form-group :label-cols-md="4" label="Ime:" label-for="name">
            <b-form-input
              id="name"
              type="text"
              size="sm"
              name="name"
              :value="client.name"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Općina:"
            label-for="township_id"
          >
            <select
              :value="client.township_id"
              id="township_id"
              class="form-control form-control-sm"
              name="township_id"
              required
            >
              <option selected disabled>Izaberite općinu</option>
              <option v-for="township in townships" :value="township.id">
                {{ township.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Adresa:" label-for="address">
            <b-form-input
              id="address"
              type="text"
              size="sm"
              name="address"
              :value="client.address"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Telefon:" label-for="phone">
            <b-form-input
              :value="client.phone"
              id="phone"
              type="text"
              size="sm"
              name="phone"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Djelatnost:"
            label-for="activity_id"
          >
            <select
              :value="client.activity_id"
              id="activity_id"
              class="form-control form-control-sm"
              name="activity_id"
              required
            >
              <option selected disabled>Izaberite djelatnost</option>
              <option v-for="activity in activities" :value="activity.id">
                {{ activity.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Dodatna djelatnost:"
            label-for="activity_secondary_id"
          >
            <select
              id="activity_secondary_id"
              class="form-control form-control-sm"
              name="activity_secondary_id"
              :value="client.activity_secondary_id"
            >
              <option selected :value="null"></option>
              <option v-for="activity in activities" :value="activity.id">
                {{ activity.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="ID Broj:"
            label-for="id_number"
          >
            <b-form-input
              :value="client.id_number"
              id="id_number"
              type="text"
              size="sm"
              name="id_number"
              pattern="[0-9]{13}"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="PDV broj"
            label-for="pdv_number"
          >
            <b-form-input
              :value="client.pdv_number"
              id="pdv_number"
              type="text"
              size="sm"
              name="pdv_number"
              pattern="[0-9]{12}"
            >
            </b-form-input>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="Vlasnik:" label-for="owner">
            <b-form-input
              :value="client.owner.name"
              id="owner"
              type="text"
              size="sm"
              name="owner"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Općina vlasnika:"
            label-for="township_owner_id"
          >
            <select
              :value="client.owner.township_id"
              id="township_owner_id"
              class="form-control form-control-sm"
              name="township_owner_id"
              required
            >
              <option selected disabled>Izaberite općinu</option>
              <option v-for="township in townships" :value="township.id">
                {{ township.name }}
              </option>
            </select>
          </b-form-group>
          <b-form-group
            :label-cols-md="4"
            label="Adresa vlasnika:"
            label-for="owner_address"
          >
            <b-form-input
              id="owner_address"
              type="text"
              size="sm"
              name="owner_address"
              :value="client.owner.address"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-form-group :label-cols-md="4" label="JMBG:" label-for="owner_jmbg">
            <b-form-input
              :value="client.owner.jmbg"
              id="owner_jmbg"
              type="number"
              size="sm"
              name="owner_jmbg"
              required
            >
            </b-form-input>
          </b-form-group>
          <b-btn type="submit" variant="primary" class="pull-right"
            >Potvrdi</b-btn
          >
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  computed: {
    selectedClient: function() {
      return this.$store.getters.selectedClient;
    }
  },
  data() {
    return {
      client: null,
      townships: null,
      activities: null
    };
  },
  methods: {
    updateClient: function(e) {
      const formData = new FormData(e.target);
      formData.append("client_id", this.selectedClient.id);
      axiOsta
        .post(api.clients + this.$route.params.id, formData)
        .then(response => {
          if (response.status === 200) {
            this.$router.push("/clients/" + response.data.id);
          }
        });
    }
  },
  created() {
    axiOsta.get(api.clients + this.$route.params.id).then(response => {
      if (response.status === 200) {
        this.client = response.data;
      }
    });
    axiOsta.get(api.townships).then(response => {
      if (response.status === 200) {
        this.townships = response.data;
      }
    });
    axiOsta.get(api.activities).then(response => {
      if (response.status === 200) {
        this.activities = response.data;
      }
    });
  }
};
</script>
